"use server";

import type { SubmissionValues } from "~/types/common";

import { isProd } from "~/lib/env";

const debug = import.meta.env.VITE_COG_DEBUG_FORMS == "1";

export async function submitSearchCountForm<T>(
  values: SubmissionValues,
): Promise<Error | Awaited<T>> {
  debug && console.log("submitted values", values);
  const formData = new FormData();

  for (const [key, value] of Object.entries(values)) {
    formData.append(key, String(value));
  }

  try {
    debug && console.log("Executing search count");
    return await fetchResultsCountFromDrupal(formData);
  } catch (err) {
    return err as Error;
  }
}

export async function submitSearchParams<T>(
  values: SubmissionValues,
): Promise<Error | Awaited<T>> {
  debug && console.log("submitted values", values);
  const formData = new FormData();

  for (const [key, value] of Object.entries(values)) {
    formData.append(key, String(value));
  }

  try {
    debug && console.log("Executing landing search nids");
    return await fetchResultsNidsFromDrupal(formData);
  } catch (err) {
    return err as Error;
  }
}

async function fetchResultsCountFromDrupal(
  formData: FormData,
  endPoint = "search-count",
) {
  const headers = new Headers();
  headers.append("User-Agent", "chrome");

  if (import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN.length > 0) {
    headers.append(
      "Authorization",
      "Basic " +
        btoa(
          import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN +
            ":" +
            import.meta.env.VITE_COG_BACKEND_AUTH_PASSWORD,
        ),
    );
  }

  let url = `${import.meta.env.VITE_COG_BACKEND_BASE_URL}/${endPoint}/`;

  if (url.indexOf("?") > -1) {
    // remove trailing slash
    url = url.replace(/\/$/, "");
  }

  try {
    const response = await fetch(url, {
      headers,
      method: "POST",
      body: formData,
    });

    debug && console.log("Response.status", response.status);

    if (response.status === 200) {
      const ret = await response.json();
      debug && console.log("Will return", ret);
      return ret;
    }

    if (response.status !== 200) {
      !isProd() &&
        console.warn(
          `Server responded with status ${response.status} for ${url}`,
        );
      return {
        code: response.status,
        error: `Server responded with status ${response.status}`,
      };
    }
  } catch (err) {
    return err as Error;
  }
}

async function fetchResultsNidsFromDrupal(
  formData: FormData,
  endPoint = "search-nids",
) {
  const headers = new Headers();
  headers.append("User-Agent", "chrome");

  if (import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN.length > 0) {
    headers.append(
      "Authorization",
      "Basic " +
        btoa(
          import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN +
            ":" +
            import.meta.env.VITE_COG_BACKEND_AUTH_PASSWORD,
        ),
    );
  }

  let url = `${import.meta.env.VITE_COG_BACKEND_BASE_URL}/${endPoint}/`;

  if (url.indexOf("?") > -1) {
    // remove trailing slash
    url = url.replace(/\/$/, "");
  }

  url += "?r=d";

  try {
    debug && console.log("Search endpoint", url);
    const response = await fetch(url, {
      headers,
      method: "POST",
      body: formData,
    });

    debug && console.log("Response.status", response.status);

    if (response.status === 200) {
      const ret = await response.json();
      debug && console.log("Will return", ret);
      return ret;
    }

    if (response.status !== 200) {
      !isProd() &&
        console.warn(
          `Server responded with status ${response.status} for ${url}`,
        );
      return {
        code: response.status,
        error: `Server responded with status ${response.status}`,
      };
    }
  } catch (err) {
    return err as Error;
  }
}

export async function submitAccessSearchCountForm<T>(
  values: SubmissionValues,
): Promise<Error | Awaited<T>> {
  debug && console.log("submitted values", values);
  const formData = new FormData();

  for (const [key, value] of Object.entries(values)) {
    formData.append(key, String(value));
  }

  try {
    debug && console.log("Executing search count");
    return await fetchResultsCountFromDrupal(
      formData,
      "solid/access/search-count",
    );
  } catch (err) {
    return err as Error;
  }
}

export async function submitAccessSearchLotsForm<T>(
  values: SubmissionValues,
): Promise<Error | Awaited<T>> {
  debug && console.log("submitted values", values);
  const formData = new FormData();

  for (const [key, value] of Object.entries(values)) {
    formData.append(key, String(value));
  }

  try {
    debug && console.log("Executing search count");
    return await fetchResultsCountFromDrupal(
      formData,
      "solid/access/search-lots",
    );
  } catch (err) {
    return err as Error;
  }
}
